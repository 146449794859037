import { lazy, ComponentType, LazyExoticComponent } from 'react'

export function loadComponentWithRetries(
  lazyComponent: () => Promise<{ default: ComponentType<any> }>,
  attemptsLeft: number,
  timeout: number // time in ms
): Promise<{ default: ComponentType<any> }> {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        if (attemptsLeft === 1) {
          reject(error)
          return
        }
        setTimeout(() => {
          loadComponentWithRetries(lazyComponent, attemptsLeft - 1, timeout).then(resolve, reject)
        }, timeout)
      })
  })
}

export default function componentLoader<T>(
  lazyComponent: () => Promise<{ default: ComponentType<T> }>
): LazyExoticComponent<ComponentType<T>> {
  return lazy(() => loadComponentWithRetries(lazyComponent, 3, 1500))
}

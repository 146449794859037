import { css } from '@emotion/react'
import React from 'react'
import cn from 'classnames'

interface TestIdProps {
  'data-testid'?: string
}

// We use an exact copy of this element in the index.html and the styles are kept in /public/style.css
export function FullPageLoader(props: TestIdProps) {
  return (
    <div className="vf-full-page-loader" {...props}>
      <WidgetLoader centered />
    </div>
  )
}

export function WidgetLoader({
  centered = true,
  className,
  ...restOfProps
}: TestIdProps & { centered?: boolean; className?: string }) {
  return (
    <div
      className={cn('vf-widget-loader', className)}
      style={{ justifyContent: centered ? 'center' : 'flex-start' }}
      {...restOfProps}
    >
      <div className="vf-ring">
        <img src="/assets/square-logo-72x72.png" alt="Vendorflow Square Logo" />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export const smallLoaderStyle = css`
  & .vf-ring {
    height: 2rem;
    width: 2rem;
  }
  & .vf-ring div {
    height: 1.25rem;
    width: 1.25rem;
    border-width: 1px;
  }
  & img {
    height: 0.75rem;
    width: 0.75rem;
  }
`

import React, { memo } from 'react'

function GenericErrorFallback({
  displayText,
  buttonText,
  buttonAction,
}: {
  displayText: string
  buttonText?: string
  buttonAction?: () => void
}) {
  return (
    <div
      style={{
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <div>{displayText}</div>
      <button
        style={{ marginTop: '0.5rem' }}
        type="button"
        onClick={buttonAction ? buttonAction : () => window.location.reload()}
      >
        {buttonText || 'Reload'}
      </button>
    </div>
  )
}

export default memo(GenericErrorFallback)

// The BUILD_ENV indicates the target environment for this app i.e. is this app pointed at dev or prod resources?
export const BUILD_ENV = process.env.VF_BUILD_ENV || ''
// The NODE_ENV indicates the mode in which webpack is producing the app e.g. "production" means it will be the more optimized js bundle
export const NODE_ENV = process.env.NODE_ENV || ''
export const GOOGLE_ANALYTICS_KEY = process.env.GOOGLE_ANALYTICS_KEY || ''
export const AMPLITUDE_KEY = process.env.VF_AMPLITUDE_KEY || ''
export const TABLE_ACTION_CELL_WIDTH = 64
export const DEBOUNCE_AVG_TYPING_TIME_MS = 300

export const isProdNodeEnv = NODE_ENV === 'production'
export const isTestEnv = NODE_ENV === 'test'
export const isProdBuild = BUILD_ENV === 'prod'
